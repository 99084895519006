import React from 'react'
import { connect } from 'react-redux'
import { Box, Heading, Text, Button } from 'grommet'
import { Apple, Android } from 'grommet-icons'

import AppLayout from '../components/app-layout'

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})

const CompletePage = () => (
  <AppLayout>
    <Box
      style={{ maxWidth: '64rem', marginLeft: 'auto', marginRight: 'auto' }}
      fill={true}
      pad='xlarge'
    >
      <Heading color='accent-2'>
        you're all set
      </Heading>
      <Text>
        We're building your profile based on your unique genetic information. It may take up to 15 minutes.
      </Text>
      <Heading
        level='2'
        size='small'
      >
        What next?
      </Heading>
      <Text>
        Your personalised reports and recommendations will be available on the Gini Health app.
      </Text>
      <br />
      <Text size="medium">
        Please download the Gini Health app or open the app on your mobile device to access your reports.
      </Text>
      <Box
        animation='fadeIn'
        direction='row'
        align="center"
        justify="center"
        margin="medium"
        gap='medium'
        wrap
      >
        <Button
          href='https://itunes.apple.com/app/gini-dna-based-nutrition/id1353057727?mt=8'
          target='_blank'
          rel='noopener noreferrer'
          icon={<Apple />}
          color="accent-1"
          label="Download for iOS"
          margin={{ vertical: "small" }}
          style={{ alignItems: 'center' }}
          primary
        />
        <Button
          href='https://play.google.com/store/apps/details?id=com.healthbit.gini&hl=en_US'
          target='_blank'
          color="accent-1"
          rel='noopener noreferrer'
          icon={<Android />}
          label="Download for Android"
          margin={{ vertical: "small" }}
          style={{ alignItems: 'center' }}
          primary
        />
      </Box>
      <Text size='medium'>
        Don’t see your reports? Email us at support@ginihealth.com. We’ll help you out right away. 
      </Text>
    </Box>
  </AppLayout>
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletePage)
